import Actions from "modules/actions";
import CptCode from "store/actions/cptCode";

const cptCodeAction = new CptCode();

export default class CptCodeAction extends Actions {
  async getAllCptCodes(variables) {
    return cptCodeAction.getAll(variables)
  }

  async getCptCodeDetails(variables) {
    return cptCodeAction.get(variables)
  }

  async createCptCode(variables) {
    return cptCodeAction.create(variables);
  }

  async updateCptCodeDetails(variables) {
    return cptCodeAction.update(variables);
  }

  async activateCptCode(variables) {
    return await cptCodeAction.activate(variables);
  }

  async deactivateCptCode(variables) {
    return await cptCodeAction.deactivate(variables);
  }
}
