import CPTCode from "store/models/CPTCode";
import AddCptCode from "./modals/AddCPTCodeModal";
import Actions from "modules/actions/cpt-code-actions";
import NotificationModal from "components/PromptModal";

const actions = new Actions();

export default {
  name: "CptCodes",
  components: { AddCptCode, NotificationModal },
  data() {
    return {
      limitData: [
        "code",
        "description",
        "validityEndDate",
        "validityStartDate",
        "lastModified",
        "isActive",
        "totalCount",
      ],
      totalCount: 0,
      isDataLoading: false,
      initialDataLoading: false,
      accountModalVisibility: false,
      orderBy: {
        code: "orderByCode",
        description: "orderByDescription",
        lastModified: "orderByDate",
        validityStartDate: "orderByValidityStartDate",
        validityEndDate: "orderByValidityEndDate",
        status: "orderByValidityStatus",
      },
      searchFilter: {
        columns: 4,
        model: CPTCode,
        fields: [
          {
            label: "Code",
            name: "code",
            type: "text",
            maxlength: 5,
          },
          {
            label: "Description",
            name: "description",
            type: "text",
            maxlength: 35,
            colSpan: 9,
          },
          {
            label: "Effective Date",
            name: "validityStartDate",
            type: "date",
            isInline: true,
          },
          {
            label: "Termination Date",
            name: "validityEndDate",
            type: "date",
            isInline: true,
          },
        ],
      },
      auditTrails: {
        id: null,
        isVisible: false,
      },
      columns: [
        {
          name: "code",
          align: "left",
          text: "CPT Code",
          field: "code",
          maxlength: 5,
          sortable: true,
        },
        {
          name: "description",
          align: "left",
          text: "CPT Code Description",
          field: "description",
          maxlength: 35,
          sortable: true,
        },
        {
          name: "validityStartDate",
          align: "left",
          text: "Effective Date",
          field: "validityStartDate",
          sortingLabel: {
            asc: "Oldest to Newest",
            desc: "Newest to Oldest",
          },
          sortable: true,
        },
        {
          name: "validityEndDate",
          align: "left",
          text: "Termination Date ",
          field: "validityEndDate",
          sortingLabel: {
            asc: "Oldest to Newest",
            desc: "Newest to Oldest",
          },
          sortable: true,
        },
        {
          name: "lastModified",
          align: "left",
          text: "Last Modified",
          field: "lastModified",
          sortable: true,
          isDateColumn: true,
        },
        {
          name: "status",
          align: "center",
          text: "Status",
          field: "status",
          sortable: true,
          isStatusColumn: true,
        },
        {
          name: "action",
          text: "Action",
          field: "action",
          align: "center",
        },
      ],
      changeStatusForm: {
        id: null,
        isVisible: false,
        isActive: false,
        isDisabled: false,
      },
      cptCodes: [],
      addModal: {
        isEditing: false,
        isCPTModalVisible: false,
        isView: false,
        submitButtonText: "Save",
        data: "",
      },
      isDiscardModalVisible: false,
      loadingData: false,
      showAuditTrail: false,
    };
  },
  computed: {
    filteredCPTCodes() {
      let data = this.cptCodes.map((item) => {
        const {
          id,
          code,
          description,
          validityEndDate,
          validityStartDate,
          lastModified,
          isActive,
        } = item;

        return {
          id: id,
          code: code,
          description,
          validityStartDate: validityStartDate
            ? this.$options.filters.getTimeDate(validityStartDate, "MM/DD/YYYY")
                .zone
            : null,
          validityEndDate: validityEndDate
            ? this.$options.filters.getTimeDate(validityEndDate, "MM/DD/YYYY")
                .zone
            : null,
          lastModified:
            this.$options.filters.changeDateFormat(
              lastModified,
              "MM/DD/YYYY hh:mm A"
            ) || "",
          status: isActive ? "Active" : "Deactivated",
          isActive,
        };
      });
      return data;
    },
  },
  methods: {
    async searchResults(result) {
      if (result.length) {
        this.totalCount = result[0].totalCount;
      } else {
        this.totalCount = 0;
      }
      this.cptCodes = result;
    },
    async fetch() {
      try {
        this.resetDataTableOptions();
        await this.$refs.searchFilter.filterSearchModel(10, null);
      } catch (e) {
        throw Error(e);
      } finally {
        this.isDataLoading = false;
      }
    },
    loading(bool) {
      this.isDataLoading = bool;
    },
    onAddCpt() {
      this.addModal.isView = false;
      this.addModal.isCPTModalVisible = true;
    },
    async onViewEditCpt(id) {
      let result;

      try {
        this.loadingData = true;
        result = await actions.getCptCodeDetails({
          variables: {
            property: {
              id: {
                type: "UUID!",
                value: id,
              },
            },
          },
          limitData: [
            "id",
            "code",
            "description",
            "validityStartDate",
            "validityEndDate",
          ],
        });
      } catch (e) {
        result = false;
        this.loadingData = false;
        this.showNotifyMessage({
          message: "Problem has occurred while fetching data.",
          type: "danger",
        });
      }

      if (result) {
        result.validityStartDate = result.validityStartDate
          ? this.$options.filters.getTimeDate(
              result.validityStartDate,
              "MM/DD/YYYY"
            ).zone
          : "";
        result.validityEndDate = result.validityEndDate
          ? this.$options.filters.getTimeDate(
              result.validityEndDate,
              "MM/DD/YYYY"
            ).zone
          : "";

        this.addModal.data = JSON.stringify(result);
        this.loadingData = false;
        this.addModal.isCPTModalVisible = true;
        this.addModal.isEditing = true;
        this.addModal.isView = true;
      }
    },
    handleFormModalVisibility(isVisible, isEditing = false) {
      this.addModal.isCPTModalVisible = isVisible;
      this.addModal.isEditing = isEditing;
      if (!isVisible) {
        this.addModal.data = "";
      }
    },
    toggleAddCPTCodeModal() {
      this.handleFormModalVisibility(true, false);
    },
    toggleEditCPTCodeModal(id) {
      this.handleFormModalVisibility(true, true);
      this.fetchCPTCodeDetails(id);
    },
    handleDiscardModalVisibility(isVisible) {
      this.isDiscardModalVisible = isVisible;
      this.addModal.data = "";
    },
    confirmDiscardChanges() {
      this.handleDiscardModalVisibility(false);
      this.handleFormModalVisibility(false, false);
    },
    toggleChangeStatus(id, isActive) {
      this.handleChangeStatusVisibility(true, id, isActive);
    },
    handleChangeStatusVisibility(value, id = null, isActive = false) {
      const { isVisible } = this.changeStatusForm;
      if (isVisible !== value) {
        this.changeStatusForm = {
          ...this.changeStatusForm,
          isVisible: value,
          id,
          isActive,
        };
      }
    },
    async getChangeStatusAction(params) {
      const { isActive } = this.changeStatusForm;
      if (isActive) {
        return await actions.deactivateCptCode(params);
      }
      return await actions.activateCptCode(params);
    },
    async onSubmitChangeStatus() {
      const { isActive, isDisabled, id } = this.changeStatusForm;
      if (!isDisabled) {
        this.changeStatusForm.isDisabled = true;
        const params = {
          id: {
            type: "UUID!",
            value: id,
            unincludeToFields: true,
          },
          limitData: ["success", "errors"],
          shouldNotReturnId: true,
        };
        try {
          const { success, errors } = await this.getChangeStatusAction(params);
          if (!success || errors.length > 0) {
            const errorMesssage =
              errors.length > 0
                ? errors[0]
                : "Problem has occurred while updating CPT Code status.";
            throw errorMesssage;
          }
          this.showNotifyMessage({
            message: `CPT Code ${isActive ? "deactivated" : "activated"}.`,
            type: "success",
          });
          this.changeStatusForm = {
            id: null,
            isVisible: false,
            isActive: false,
            isDisabled: false,
          };
          this.fetch();
        } catch (error) {
          this.showNotifyMessage({
            message: error,
            type: "danger",
          });
          this.changeStatusForm.isDisabled = false;
        }
      }
    },
    handleAuditTrailVisibility(bool) {
      this.showAuditTrail = bool;
    },
    async onSave() {
      await this.fetch();
    },
    resetDataTableOptions() {
      this.$refs.dataTable.resetDataTableOptions();
    },
    toggleAuditTrail(isVisible, id = null) {
      this.auditTrails = {
        id,
        isVisible,
      };
    },
  },
  async mounted() {
    try {
      this.initialDataLoading = true;
      await this.fetch();
    } catch (err) {
      this.showNotifyMessage({
        message: "Problem has occurred while fetching data.",
        type: "danger",
      });
    } finally {
      this.initialDataLoading = false;
    }
  },
};
