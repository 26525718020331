import CPTCode from "store/models/CPTCode";

import StoreActions from "store/actions";

const storeActions = new StoreActions(CPTCode);
export default class CptCodeAction {
  async getAll(variables, desciption = "") {
    return await storeActions.query(
      {
        queryName: "cptCodes",
        relativeUrl: "/cpt-code/get-cpt-code",
        ...variables,
      },
      desciption.length ? desciption : "to load cpt list."
    );
  }

  async get(params) {
    const { limitData, variables } = params;
    return await storeActions.query(
      {
        queryName: "getCptCode",
        relativeUrl: "/cpt-code/get-cpt-code",
        limitData: limitData || null,
      },
      "To fetch cpt code details.",
      {
        ...variables,
      }
    );
  }

  async create(variables) {
    return await storeActions.mutation(
      {
        queryName: "createCptCode",
        relativeUrl: "/cpt-code/create-cpt-code",
      },
      "To create cpt code.",
      {
        ...variables,
      }
    );
  }

  async update(params) {
    const { queries, variables, collectionQueries } = params;
    return await storeActions.mutation({
      queryName: "updateCptCodeDetails",
      relativeUrl: "/cpt-code/update-cpt-code",
      queries: queries || null,
      collectionQueries: collectionQueries || [],
    }, "To update cpt code..", {
        ...variables,
      }
    );
  }

  async activate(variables) {
    const { id, limitData = [], shouldNotReturnId = false } = variables;
    return await storeActions.mutation({
      queryName: "activateCptCode",
      relativeUrl: "/cpt-code/activate",
      limitData,
      shouldNotReturnId,
    }, "To activate CPT code.", {
      id,
    });
  }

  async deactivate(variables) {
    const { id, limitData = [], shouldNotReturnId = false } = variables;
    return await storeActions.mutation({
      queryName: "deactivateCptCode",
      relativeUrl: "/cpt-code/deactivate",
      limitData,
      shouldNotReturnId,
    }, "To deactivate CPT code.", {
      id,
    });
  }
}
