var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',[_c('v-row',[_c('v-col',[_c('v-searchfilter',{ref:"searchFilter",attrs:{"options":_vm.searchFilter.fields,"modelInstance":_vm.searchFilter.model,"columns":_vm.searchFilter.columns,"limitData":_vm.limitData,"limit":10,"isLoading":_vm.isDataLoading,"hasArchiveText":"Include Deactivated","defaultSort":{
            by: 'orderByCode',
            order: 'ASC',
          },"hasArchive":"","hideDeleted":"","allowFutureDates":"","relativeUrl":"/cpt-code/all-cpt-code","queryName":"cptCodes"},on:{"searchResults":_vm.searchResults,"resetDataTableOptions":_vm.resetDataTableOptions,"loading":_vm.loading}}),_c('v-custom-datatable',{ref:"dataTable",staticClass:"data-table",attrs:{"activeLabel":"Activate","addLabel":"Add CPT Code","editLabel":"View/Edit","noDataLabel":"No CPT Codes found","rowKey":"id","accessControl":{
            authUserHasAddAccessControl: true,
            authUserHasEditAccessControl: true,
            authUserHasDeleteAccessControl: true,
          },"columns":_vm.columns,"data":_vm.filteredCPTCodes,"isLoading":_vm.isDataLoading,"initialLoading":_vm.initialDataLoading,"orderBy":_vm.orderBy,"searchFilter":_vm.$refs.searchFilter,"totalCount":_vm.totalCount,"bordered":"","flatx":"","hasAuditTrail":"","hasChangeStatus":"","hideDelete":"","hideView":"","withAdd":""},on:{"onAdd":_vm.onAddCpt,"onChangeStatus":_vm.toggleChangeStatus,"onEdit":_vm.onViewEditCpt,"onToggleAudit":function (id) { return _vm.toggleAuditTrail(true, id); }}})],1)],1)],1),_c('AddCptCode',{attrs:{"isEditing":_vm.addModal.isEditing,"isView":_vm.addModal.isView,"isCPTModalVisible":_vm.addModal.isCPTModalVisible,"defaultValues":_vm.addModal.data,"isLoading":_vm.loadingData,"submitButtonText":"Save"},on:{"toggleDiscardModal":function($event){_vm.isDiscardModalVisible = $event},"toggleFormModal":_vm.handleFormModalVisibility,"refetchData":_vm.fetch}}),_c('v-prompt-modal',{ref:"promptModal",attrs:{"buttons-elevation":0,"promptVisibility":_vm.isDiscardModalVisible,"message":"You haven't saved your entries.<br/>Are you sure you want to discard them?","positiveBtnTxt":"Keep Editing","negativeBtnTxt":"Discard","width":"fit-content","text-center":"","no-divider":""},on:{"update:promptVisibility":function($event){_vm.isDiscardModalVisible=$event},"update:prompt-visibility":function($event){_vm.isDiscardModalVisible=$event},"doCancel":_vm.confirmDiscardChanges,"doOk":function($event){_vm.isDiscardModalVisible = false}}}),_c('v-change-status',{attrs:{"visible":_vm.changeStatusForm.isVisible,"id":_vm.changeStatusForm.id,"isActive":_vm.changeStatusForm.isActive,"isDisabled":_vm.changeStatusForm.isDisabled,"header":"Change Status","message":"You are about to change the status of this code.","warning":"Changing the status will update the Termination Date to today's date and the deactivation will only take effect after the termination date.","buttonTexts":{
      cancel: 'Cancel',
      activate: 'Activate',
      deactivate: 'Deactivate',
    }},on:{"close":function($event){return _vm.handleChangeStatusVisibility(false)},"submit":_vm.onSubmitChangeStatus}}),_c('v-loading',{attrs:{"type":"form","displayModal":_vm.loadingData,"mesg":"Please wait..."}}),(_vm.auditTrails.isVisible && _vm.auditTrails.id)?_c('v-audit-trail',{attrs:{"module":"CPT_CODE","recordId":_vm.auditTrails.id,"visibility":_vm.auditTrails.isVisible},on:{"onClose":_vm.toggleAuditTrail}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }